<template>
    <nav :class="classes">
        <slot />
    </nav>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "flex flex-col",
        };
    },
    computed: {
        classes() {
            return `vertical-menu ${this.baseClasses}`;
        },
    },
};
</script>
